import { FormikValues, useFormik } from 'formik';
import { graphql, navigate } from 'gatsby';
import { chunk } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import CheckboxComponent from 'ui-kit-v2/checkbox/checkbox';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';

import ToastBox from 'ui-kit/toast-box/toast-box';

import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import { setOnboardingHealthProfile } from 'state/account/account.reducers';
import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    medicalConditionsAddOrUpdateAllRoutine,
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from 'state/medical-conditions/medical-conditions.routines';
import { isLoadingMedicalConditionsSelector } from 'state/medical-conditions/medical-conditions.selector';
import { registrationContinueRegistrationSelector } from 'state/registration/registration.selector';

import { BREAKPOINTS } from 'const/breakpoints';

import { PROFILE_HEALTH_PROFILE } from 'schema/health-profile.schema';

import { HealthConditionsMapped } from 'types/health-profile';

import useHealthConditions from 'hooks/useHealthConditions';
import { useOnboardingRedirection } from 'hooks/useOnboardingRedirection';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './health-profile.styles.scss';

interface OnboardingHealthProfilePageProps {
    data: GatsbyTypes.HealthBenefitsPagePageDataQuery;
}

interface healthConditionsForm {
    healthConditions: string[];
    customHealthCondition: string;
    allergiesConditions: string[];
    customAllergyCondition: string;
}

const OnboardingHealthProfilePage = ({ data }: OnboardingHealthProfilePageProps) => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const { shouldSetupAutoRefill } = useOnboardingRedirection(true);
    const { getHealthConditionsByPatientNum } = useHealthConditions();

    // Selectors
    const profileObject = useSelector(accountProfileSelector);
    const continueRegistrationResponseState = useSelector(registrationContinueRegistrationSelector);
    const { registration } = continueRegistrationResponseState;

    const registeredEpostNum = registration?.patientRegistration?.epostPatientNum || profileObject?.epostPatientNum;
    const isLoadingMedicalConditions = useSelector(isLoadingMedicalConditionsSelector);

    const isMembershipHealthConditions = registration?.planAlias === 'BRD02' || profileObject?.hasMembership;
    const healthConditions: HealthConditionsMapped | undefined = getHealthConditionsByPatientNum(
        profileObject?.epostPatientNum
    );

    const initialValues: healthConditionsForm = {
        healthConditions: [],
        customHealthCondition: '',
        allergiesConditions: [],
        customAllergyCondition: ''
    };

    const isNone = (condition: string | undefined) => condition === 'None' || condition === 'none';

    const makeHealthConditionsPayload = (values: FormikValues) => {
        const healthConditionsPayload: any = {
            ePostPatientNum: registeredEpostNum,
            Other: values?.customHealthCondition
        };

        (healthConditions?.conditionChoices as string[]).forEach((cond: string) => {
            healthConditionsPayload[cond] = false;
        });

        values?.healthConditions.forEach((cond: string) => {
            healthConditionsPayload[cond] = true;
        });

        return healthConditionsPayload;
    };

    const makeAllergiesPayload = (values: FormikValues) => {
        const allergiesPayload: any = {
            ePostPatientNum: registeredEpostNum,
            Other: values?.customAllergyCondition
        };

        (healthConditions?.allergyChoices as string[]).forEach((cond: string) => {
            allergiesPayload[cond] = false;
        });

        values?.allergiesConditions.forEach((cond: string) => {
            allergiesPayload[cond] = true;
        });

        return allergiesPayload;
    };

    const handleSubmit = useCallback(
        async (values: FormikValues) => {
            const healthConditionsPayload = makeHealthConditionsPayload(values);
            const allergiesPayload = makeAllergiesPayload(values);

            dispatch(
                // trigger the two existing apis for medical conditions
                medicalConditionsAddOrUpdateAllRoutine({
                    medicalConditions: healthConditionsPayload,
                    allergies: allergiesPayload,
                    onSuccess: () => {
                        formik.resetForm();
                        formik.setValues({
                            healthConditions: [],
                            customHealthCondition: '',
                            allergiesConditions: [],
                            customAllergyCondition: ''
                        });
                        // to avoid a new API request,
                        // hasHealthProfileCompleted was set to TRUE to update the useOnboardingRedirection conditions
                        dispatch(setOnboardingHealthProfile());
                        // redirect to AutoRefill page
                        if (shouldSetupAutoRefill) {
                            navigate('/secure/onboarding/auto-refill');
                        } else {
                            navigate('/secure/onboarding/mobile-phone-confirm-your-number');
                        }
                    },
                    onError: () => {
                        dispatch(
                            openModal({
                                showClose: false,
                                type: 'danger',
                                size: 'lg',
                                onClose: () => {
                                    dispatch(closeModal({}));
                                },
                                headerContent: (
                                    <BirdiModalHeaderDanger
                                        headerText={t('pages.onboarding.healthProfile.sections.errors.modal.title')}
                                        icon="alert"
                                    />
                                ),
                                bodyContent: (
                                    <BirdiModalErrorContent
                                        description={t(
                                            'pages.onboarding.healthProfile.sections.errors.modal.errorMessage'
                                        )}
                                        ctaText={t('pages.onboarding.healthProfile.sections.errors.modal.gotIt')}
                                        onClose={() => {
                                            dispatch(closeModal({}));
                                        }}
                                    />
                                ),
                                ctas: []
                            })
                        );
                    }
                })
            );
        },
        [shouldSetupAutoRefill, navigate, makeHealthConditionsPayload, makeAllergiesPayload]
    );

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: PROFILE_HEALTH_PROFILE,
        onSubmit: handleSubmit
    });

    const healthChunks = useMemo(() => {
        const otherConditions = healthConditions?.conditionChoices.slice(1);
        const chunks = chunk(otherConditions, 3);
        return [['None'], ...chunks];
    }, [healthConditions?.conditionChoices]);

    const allergiesChunks = useMemo(() => {
        const otherConditions = healthConditions?.allergyChoices.slice(1);
        const chunks = chunk(otherConditions, 3);
        return [['None'], ...chunks];
    }, [healthConditions?.allergyChoices]);

    const handleChange = useCallback(
        (
            item: string,
            isChecked: boolean,
            currentConditions: string[],
            setFieldValue: (field: string, value: any) => void,
            fieldName: string
        ) => {
            let updatedConditions = [...currentConditions];

            if (item === 'None') {
                if (isChecked) {
                    updatedConditions = ['None'];
                } else {
                    updatedConditions = updatedConditions.filter((cond) => cond !== 'None');
                }
            } else {
                updatedConditions = updatedConditions.filter((cond) => cond !== 'None');
                if (isChecked) {
                    updatedConditions.push(item);
                } else {
                    updatedConditions = updatedConditions.filter((cond) => cond !== item);
                }
            }

            setFieldValue(fieldName, updatedConditions);
        },
        [formik]
    );

    useEffect(() => {
        if (registeredEpostNum) {
            if (isMembershipHealthConditions) {
                dispatch(
                    medicalConditionsDetailsRoutine.trigger({
                        epostPatientNumFamily: registeredEpostNum
                    })
                );
                dispatch(
                    medicalConditionsAllergiesDetailsRoutine.trigger({
                        epostPatientNumFamily: registeredEpostNum
                    })
                );
            }
        }
    }, [dispatch, registeredEpostNum, isMembershipHealthConditions]);

    return (
        <WorkflowLayout
            className="onboarding-health-profile-page"
            metaData={{ nodeTitle: t('pages.onboarding.healthProfile.pageTitle') }}
            isUnauthenticatedFlow={false}
        >
            <LayoutWrapperComponent
                title={t('pages.onboarding.healthProfile.pageTitle')}
                copyText={t('pages.onboarding.healthProfile.pageCopy')}
                headerAlign="left"
                workflowNavigation={<WorkflowNavigationSection limit={5} usage={2} />}
            >
                <form className="onboarding-health-profile-page__sections" onSubmit={formik.handleSubmit} noValidate>
                    <div className="health-profile-section">
                        <div className="health-profile-section__title">
                            <h2>{t('pages.onboarding.healthProfile.sections.healthTitle')}</h2>
                            <p>{t('pages.onboarding.healthProfile.sections.healthSubTitle')}</p>
                            {formik.touched.healthConditions && formik.errors.healthConditions && (
                                <ToastBox variant="error-v2" icon="error-v2">
                                    {formik.errors.healthConditions}
                                </ToastBox>
                            )}
                        </div>

                        <div className="health-profile-section__list">
                            {healthChunks &&
                                healthChunks.map((conditions, rowIndex) => (
                                    <div className="health-profile-section__list__row" key={rowIndex}>
                                        {conditions.map((item, itemIndex) => (
                                            <div className="health-profile-section__list__row__item" key={itemIndex}>
                                                <CheckboxComponent
                                                    {...formik.getFieldProps('healthConditions')}
                                                    label={
                                                        item === 'None'
                                                            ? t(
                                                                  'pages.onboarding.healthProfile.sections.form.noKnownConditions'
                                                              )
                                                            : item
                                                    }
                                                    value={item}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            item as string,
                                                            e.target.checked,
                                                            formik.values.healthConditions,
                                                            formik.setFieldValue,
                                                            'healthConditions'
                                                        )
                                                    }
                                                    isSelected={
                                                        isNone(item)
                                                            ? (formik.values.healthConditions as string[]).includes(
                                                                  'None'
                                                              )
                                                            : (formik.values.healthConditions as string[]).includes(
                                                                  item as string
                                                              )
                                                    }
                                                    disabled={
                                                        (isNone(item) &&
                                                            formik.values.healthConditions.some(
                                                                (condition) => condition !== 'None'
                                                            )) ||
                                                        isLoadingMedicalConditions
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                        </div>

                        <div className="health-profile-section__optional">
                            <TextInputComponent
                                {...formik.getFieldProps('customHealthCondition')}
                                label={t('pages.onboarding.healthProfile.sections.form.othersConditions')}
                                variant={width > BREAKPOINTS.md ? 'alt' : 'default'}
                            />
                        </div>
                    </div>
                    <div className="health-profile-section">
                        <div className="health-profile-section__title">
                            <h2>{t('pages.onboarding.healthProfile.sections.allergiesTitle')}</h2>
                            <p>{t('pages.onboarding.healthProfile.sections.allergiesSubTitle')}</p>
                            {formik.touched.allergiesConditions && formik.errors.allergiesConditions && (
                                <ToastBox variant="error-v2" icon="error-v2">
                                    {formik.errors.allergiesConditions}
                                </ToastBox>
                            )}
                        </div>

                        <div className="health-profile-section__list">
                            {allergiesChunks &&
                                allergiesChunks.map((conditions, rowIndex) => (
                                    <div className="health-profile-section__list__row" key={rowIndex}>
                                        {conditions.map((item, itemIndex) => (
                                            <div className="health-profile-section__list__row__item" key={itemIndex}>
                                                <CheckboxComponent
                                                    {...formik.getFieldProps('allergiesConditions')}
                                                    label={
                                                        item === 'None'
                                                            ? t(
                                                                  'pages.onboarding.healthProfile.sections.form.noKnownAllergies'
                                                              )
                                                            : item
                                                    }
                                                    value={item}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            item as string,
                                                            e.target.checked,
                                                            formik.values.allergiesConditions,
                                                            formik.setFieldValue,
                                                            'allergiesConditions'
                                                        )
                                                    }
                                                    isSelected={
                                                        isNone(item)
                                                            ? (formik.values.allergiesConditions as string[]).includes(
                                                                  'None'
                                                              )
                                                            : (formik.values.allergiesConditions as string[]).includes(
                                                                  item as string
                                                              )
                                                    }
                                                    disabled={
                                                        (item === 'None' &&
                                                            formik.values.allergiesConditions.some(
                                                                (condition) => condition !== 'None'
                                                            )) ||
                                                        isLoadingMedicalConditions
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                        </div>

                        <div className="health-profile-section__optional">
                            <TextInputComponent
                                {...formik.getFieldProps('customAllergyCondition')}
                                label={t('pages.onboarding.healthProfile.sections.form.othersAllergies')}
                                variant={width > BREAKPOINTS.md ? 'alt' : 'default'}
                            />
                        </div>
                    </div>

                    <ButtonComponent
                        className="profile-setup-page__button_submit"
                        label={t('pages.onboarding.healthProfile.sections.submit')}
                        type="submit"
                        full
                        disabled={isLoadingMedicalConditions}
                        isLoading={isLoadingMedicalConditions}
                    />
                </form>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default OnboardingHealthProfilePage;

export const query = graphql`
    query OnboardingHealthProfilePage($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
